import React from "react";
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Form from "../components/stay-connected-form";
import Modal from 'react-modal';
import closeIcon from '../_images/icons/white/close.svg';
import ReactGA from 'react-ga';
import DOD from '../components/dods';

import Frame1 from '../_images/profiles/trade-2.jpg';
import Frame2 from '../_images/profiles/trade-3.jpg';
import socialBanner from '../_images/profiles/trade-social2.jpg';

Modal.setAppElement('#___gatsby')

export default class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      width: 0,
      height: 0
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.logger = this.logger.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: 'Receive Updates'
    });
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  logger(e) {
    // ga('send', 'event', 'Button', 'Give Now', 'Campaign Profile');
    ReactGA.event({
      category: 'Button',
      action: 'User clicked CTA',
      label: e.currentTarget.textContent
    });
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  
  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    var pageTitle = 'Titans of Trade';
    var pageDesc = 'Using real dollars, students in the Titan Capital Management program at Cal State Fullerton get firsthand experience in securities analysis and portfolio management — and a competitive edge in the workplace.';

    // console.log('width: ' + this.state.width );
    var windowHeight = {
      minHeight: this.state.height
    }
    return (
      <React.Fragment>
      <Layout locationInfo={this.props.location}>
        <Helmet>
          <title>{pageTitle}</title>
          <body className="profile-landing-page" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />

          <link href="https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&display=swap" rel="stylesheet"></link>
        </Helmet>
        <section className="landing-wrap trade" style={windowHeight}>
          <div className="profile-text" style={windowHeight}>
            <div className="text-wrap">
              
              <div className="row">
                <h1>{pageTitle}</h1>
                <div className="col-12">

                <p>Working with almost $3 million in assets, students in the Titan Capital Management program at Cal State Fullerton get firsthand experience in securities analysis and portfolio management — and a competitive edge in the workplace.</p>
                <p>For finance students seeking future careers as investors, securities analysts or money managers, there’s no better return on investment than participation in TCM, the university’s premier student-run investment program.</p>
                <p>Using the same market data that drives trading floors, global finance and investment operations, TCM students evaluate the economic landscape and potential investments and make recommendations before purchasing in real time.</p>
                <p>Making savvy choices matters, because proceeds from the program benefit CSUF students, supporting a university scholarship and the business college’s tutoring center.</p>
                <p>With Titan Capital Management, Titans get the investment skills valued by employers and are ready to contribute on the job from day one.</p>


                  <h2>Be the difference.</h2>
                  <p><strong>Help support Titan Capital Management at Cal State Fullerton today.</strong></p>
                  <ul className="actions">
                    <li><a href="http://give.fullerton.edu/10400-ASAP" className="buttonBlue" onClick={this.logger}>Donate Now</a></li>
                    <li><button className="buttonBlue outline" onClick={this.openModal}>Receive Updates</button></li>
                  </ul>

                  <DOD layout="2" college="cbe" />

                </div>
              </div>
              
            </div>

            

          </div>
          <img src={Frame1} className="frame animated fadeIn" id="frame1" alt="Group of Titan Capital Management students in conversation" />
          <img src={Frame2} className="frame animated fadeIn" id="frame2" alt="3 Titan Capital Management students stand in front of financial graph projection" />
         
        </section>

        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Receive Updates"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <div className="wrap">
            <Form profile="trade" college="the College of Business and Economics" />
          </div>
        </Modal>
        
      </Layout>
      </React.Fragment>
    );
  }
}
